import React from "react";

const dev_spec = [
  {
    title: "Power",
    datas: [
      {
        title: "Max Power",
        desc: "14.5 bph",
      },
      { title: "Brake Type", desc: "Disc" },
      { title: "Fuel Efficiency", desc: "40 kmpl" },
      {
        title: "Design",
        desc: "Wide Tyres, USD Front Forks, ABS, All-Digital Console, Head-Turning Design",
      },
      { title: "Clutch", desc: "Wet Multi-Disc" },
      { title: "Front Suspension", desc: "43mm WP Upside Down Forks" },
      { title: "Rear Suspension", desc: "WP Monoshock" },
      {
        title: "Suspension Travel(Front)",
        desc: "Triple rate spring, Inverted telescopic fork (USD)",
      },
      {
        title: "Suspension Travel(Gear)",
        desc: "Double rate, Mono shock- 10 steps (Adjuster Slots)",
      },
      { title: "Front Brake", desc: "300mm?" },
      { title: "Rear Brake", desc: "230mm?" },
      { title: "Fuel Type/ Minimum Octane Rating", desc: "91 octane" },
      { title: "Cooling System", desc: "Liquid Cooling" },
      { title: "Front Tyre", desc: "110/70-R17" },
      { title: "Rear Tyre", desc: "150/60-R17" },
    ],
  },
  {
    title: "Performance",
    datas: [
      { title: "ABS", desc: "Single Channel" },
      { title: "Displacement", desc: "124.7 cc" },
      { title: "bore", desc: "58?mm" },
      { title: "Stroke", desc: "47.2?mm" },
      { title: "Transmission", desc: "Manual" },
      { title: "Cooling", desc: "Liquid Cooling" },
      { title: "Engine Type", desc: "124.7cc, Single Cylinder, Liquid Cooled" },
      { title: "Compression ratio", desc: "14.5", desc: "1" },
      { title: "Fuel system", desc: "Fuel Injection" },
      { title: "Ignition", desc: "Digital Ignitions" },
      { title: "Maximum power", desc: "14.5 HP @ 9250 rpm" },
      { title: "Maximum torque", desc: "12 Nm @ 8000 rpm" },
      { title: "Valve system", desc: "4 velves" },
    ],
  },
  {
    title: "Details",
    datas: [
      { title: "Kerb Weight", desc: "159 kg" },
      { title: "Frame", desc: "Trellis frame" },
      { title: "Overall Length", desc: "2002 mm" },
      { title: "Overall Width", desc: "873 mm" },
      { title: "Overall Height", desc: "1274 mm" },
      { title: "Suspension", desc: "43mm WP Upside Down Forks" },
      { title: "Starter", desc: "Self Starter" },
      { title: "Lubrication", desc: "Wet sump" },
      { title: "Primary Drive", desc: "22/72" },
      { title: "Frame Design", desc: "Trellis" },
      { title: "Front Brake Disc Diameter", desc: "300mm?" },
      { title: "Rear Brake Disc Diameter", desc: "230mm?" },
      { title: "Chain", desc: "5/8 x 1/4? X-Ring" },
      { title: "Wheelbase", desc: "1.366 m" },
      { title: "Ground Clearance", desc: "155 mm" },
      { title: "Seat Height", desc: "818 mm" },
      { title: "Tank Capacity(Approx.)", desc: "13.5 L" },
      { title: "Dry Weight", desc: "141.4 kg" },
      { title: "Frame Type", desc: "Trellis" },
      { title: "Color Choices", desc: "Electronic Orange and Ceramic White" },
      { title: "Warranty", desc: "2 Years/ 30,000 km" },
    ],
  },
];

export { dev_spec };
