import axios from "axios";
import { baseUrl, baseUrlimg } from "../module/api_init";
import { faq, reviews, testimonials } from "../module/static_data";

async function getAllBikes(setstate) {
  await axios
    .get(baseUrl + "seasons")
    .then((res) => {
      var data = res.data;
      var datas = [
        [
          { title: "", bikes: [] },
          { title: "", bikes: [] },
          { title: "", bikes: [] },
        ],
        [
          { title: "", bikes: [] },
          { title: "", bikes: [] },
          { title: "", bikes: [] },
        ],
        [
          { title: "", bikes: [] },
          { title: "", bikes: [] },
          { title: "", bikes: [] },
        ],
      ];
      for (let k = 0; k < data.length; k++) {
        datas[data[k].b_position - 1][data[k].i_position - 1].title =
          data[k].season_name;
        datas[data[k].b_position - 1][data[k].i_position - 1].bikes.push(
          data[k].bikes
        );
      }
      setstate({ bikes: datas });
    })
    .catch(() => setstate({ error: "Not Fount" }));
}

async function getAllBanner(setstate) {
  await axios
    .get(baseUrl + (window.innerWidth < 600 ? "mobilebanner" : "banner"))
    .then((res) => {
      var ban = [];
      var data = res.data;
      for (let i = 0; i < data.length; i++) ban.push("");
      for (let i = 0; i < data.length; i++)
        ban[data[i].position - 1] = baseUrlimg + data[i].banner_img;
      setstate({ banner: ban });
    })
    .catch(() => setstate({ error: "Not Fount" }));
}

async function getAllBrands(setstate) {
  await axios
    .get(baseUrl + "category")
    .then((res) => setstate({ brands: res.data }))
    .catch(() => setstate({ error: "Not Fount" }));
}

async function getFaq(setstate) {
  setstate({ faq: faq });
}

async function getTestimonials(setstate) {
  setstate({ testimonials: testimonials });
}

async function getReviews(setstate) {
  setstate({ reviews: reviews });
}

async function getMetatags(setstate) {
  await axios
    .get(baseUrl + "model")
    .then((res) => setstate({ metatags: res.data }))
    .catch(() => setstate({ error: "Not Fount" }));
  return 0;
}

async function getAllhomeData(setstate) {
  setstate({ loading: true });
  await getAllBanner(setstate);
  await getAllBikes(setstate);
  await getAllBrands(setstate);
  await getFaq(setstate);
  await getTestimonials(setstate);
  await getReviews(setstate);
  await getMetatags(setstate);
  setstate({ loading: false });
}

export { getAllhomeData, getAllBikes };
