import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import AllBike from "./screen/allbike";
import Bike from "./screen/bike";
import ContactUs from "./screen/contact_us";
import Home from "./screen/home";
import { useEffect } from "react";
import ReactGA from "react-ga";
import Sitemap from "./sitemap";
ReactGA.initialize("UA-126056874-1");

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/ktm-bikes"
          render={(props) => (
            <Bike location={props.location.pathname.split("/")[2]} />
          )}
        />
        <Route path="/all-ktm-bikes" component={AllBike} />
        <Route path="/contactus" component={ContactUs} />
        <Route path="/sitemap_data" component={Sitemap} />
        <Route path="/" component={Home} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
