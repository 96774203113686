import React, { useState } from "react";

function BikeBodyHeader({ executeScroll, page }) {
  const titles = [
    { name: "Overview", id: "overview" },
    { name: "Colours", id: "colours" },
    { name: "Specification", id: "specification" },
    { name: "Price", id: "price" },
    { name: "Summary", id: "summery" },

    // "Similar Bikes",
    // "Mileage",
    // "Specs",
    // "User Reviews",
    // "News",
    // "Videos",
  ];

  return (
    <div className="bk1_bh1_z">
      <div className="bk1_bh1_a">
        <div className="bk1_bh1_b">
          {titles.map((title, k) => (
            <div key={k} onClick={() => executeScroll(title.id, k)}>
              <div className="bk1_bh1_ba">{title.name}</div>
              <div
                className="bk1_bh1_bb"
                style={k === page ? {} : { visibility: "hidden" }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BikeBodyHeader;
