import { setUrlimg } from "../module/api_init";
import { getComaPrice, makespaceline } from "../module/simple";

function SingleBike({ bike, show }) {
  const allImg = bike.image.split("|");
  return (
    <div className="hm1_bk1_e">
      <div
        onClick={() =>
          window.location.assign(
            `/ktm-bikes/${bike.route}/${makespaceline(bike.name)}`
          )
        }
      >
        <div className="hm1_bk1_ez">
          <img
            alt="No Image"
            width="291"
            height="194"
            src={allImg.length === 0 ? "" : setUrlimg(allImg[0])}
          />
        </div>
        <div className="hm1_bk1_ea">{bike.name}</div>
        {/* <div className="hm1_bk1_eb">349 cc, 35 kmpl, 20.2 bhp, 191 kg</div> */}
        <div className="hm1_bk1_ec">Avg. Ex-showroom price</div>
        <div className="hm1_bk1_ed">₹ {getComaPrice(bike.price)} onwards</div>
      </div>
      <div className="row">
        <div onClick={show} className="hm1_bk1_ee">
          Check on-road price
        </div>
      </div>
    </div>
  );
}

export default SingleBike;
