import { Carousel } from "react-responsive-carousel";
import { kalyaniDark, ktmLogo, phoneIcon } from "../module/image_icon";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import React from "react";

function HomeTop({ bg, banner }) {
  const titles = [
    "BIKES",
    "PRICE CHART",
    "BOOK A TEST RIDE",
    "SERVICE BOOKING",
    "ABOUT US",
    "CONTACT US",
  ];
  return (
    <React.StrictMode>
      <div className="hm1_tb1_1a">
        <Carousel
          swipeable={true}
          showThumbs={false}
          autoPlay={true}
          infiniteLoop={true}
          interval={2000}
          showArrows={window.innerWidth < 600 ? false : true}
        >
          {banner.map((ban) => (
            <img className="hm1_tp1_i" src={ban} />
          ))}
        </Carousel>
      </div>
      <div className="hm1_tp1_a">
        <img alt="img" className="hm1_tp1_c" src={kalyaniDark} />
        <div className="hm1_tp1_d"></div>
        <div className="hm1_tp1_f">
          <div
            className="hm1_tp1_e"
            onClick={() => window.location.assign("/all-ktm-bikes")}
          >
            ALL KTM BIKES
          </div>
          <div
            className="hm1_tp1_g"
            onClick={() => window.location.assign("tel:8095699900")}
          >
            <img src={phoneIcon} width="15" /> &nbsp;&nbsp;80956 99900
          </div>

          <img
            alt="img"
            onClick={() => window.location.assign("https://www.ktm.com/")}
            className="hm1_tp1_h"
            src={ktmLogo}
          />
        </div>
      </div>
    </React.StrictMode>
  );
}

export default HomeTop;
