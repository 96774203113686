import React from "react";
import { baseUrlimg } from "../module/api_init";
import { getComaPrice } from "../module/simple";

function BikePrice({ bike, setenquiry }) {
  return (
    <div id="price" className="center">
      <div className="bk2_pr_a">
        <div className="cm1_bk_subtitle">{bike.title} Price</div>
        <div className="bk2_pr_ac">
          <div className="bk2_pr_aca" style={{ width: "40%  " }}>
            Variant
          </div>
          <div className="bk2_pr_aca">Price</div>
          <div className="bk2_pr_aca"></div>
        </div>
        <div className="bk2_pr_aa" />
        {bike.varients.map((item, k) =>
          item.products.map((it, i) => (
            <React.StrictMode key={k + i}>
              <div className="bk2_pr_ac">
                <div className="bk2_pr_ada">
                  {it.name} {it.colors[0].colour_name}
                </div>
                <div className="bk2_pr_adb">
                  ₹ {getComaPrice(it.price)} /-
                  <div className="bk2_pr_adba">Avg. Ex-showroom price</div>
                </div>
                <div className="bk2_pr_adc">
                  <div
                    className="bk1_dt1_cbiab"
                    onClick={() =>
                      window.open(baseUrlimg + bike.file, "_blank")
                    }
                  >
                    Download Brochure
                  </div>

                  {/* <div className="bk1_dt1_cbiab" onClick={() => setenquiry(0)}>
                    Get On-road Price
                  </div> */}
                </div>
              </div>
              {it.length - 1 != k ? <div className="bk2_pr_aa" /> : ""}
            </React.StrictMode>
          ))
        )}
      </div>
    </div>
  );
}

export default BikePrice;
