const baseUrl = "https://ktmapi.kalyaniktm.com/api/";
const baseUrlimg = "https://ktmadmin.kalyaniktm.com/";
const baseUrlimgloc = "https://ktmapi.kalyaniktm.com/storage/";
const baseUrllogo = "https://ktmadmin.kalyaniktm.com/uploads/Logo/";

// const baseUrl = "https://bajajapi.kalyaniktm.com/api/";
// const baseUrlimg = "https://bajajadmin.kalyaniktm.com/";
// const baseUrlimgloc = "https://bajajapi.kalyaniktm.com/storage/";
// const baseUrllogo = "https://bajajadmin.kalyaniktm.com/uploads/Logo/";

const setUrlimg = (v) => baseUrlimg + v;
const setUrlimgLoc = (v) => baseUrlimgloc + v.split("/")[1];

export { baseUrl, baseUrlimg, setUrlimg, baseUrllogo, setUrlimgLoc };
