import React from "react";
import HomeBikes from "../compnent/home_bikes";
import HomeBrands from "../compnent/home_brand";
import HomeFooter from "../compnent/home_footer";
import HomeOnroudPrice from "../compnent/home_onroadprice";
import HomeQuestions from "../compnent/home_questions";
import HomeRewies from "../compnent/home_review";
import HomeTestride from "../compnent/home_testride";
import HomeTop from "../compnent/home_top";
import { Errorpage, LoadingPage } from "../compnent/loading_error";
import { EnquiryPopup } from "../compnent/popups";
import { getAllhomeData } from "../method/home";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerBg: false,
      loading: true,
      error: "",
      bikes: [],
      seasonbikes: [],
      showEnquiry: -1,
      banner: [],
      brands: [],
      faq: [],
      testimonials: [],
      reviews: [],
      metatags: [],
    };
  }

  componentDidMount() {
    getAllhomeData((v) => this.setState(v));
    window.addEventListener("scroll", () => {
      if (window.scrollY > window.screen.width / 3)
        this.setState({ headerBg: true });
      else this.setState({ headerBg: false });
    });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", () => {});
  }

  setenquiry(v) {
    this.setState({ showEnquiry: v });
  }

  render() {
    const {
      headerBg,
      bikes,
      loading,
      error,
      showEnquiry,
      banner,
      brands,
      faq,
      reviews,
      metatags,
    } = this.state;
    return loading ? (
      <LoadingPage />
    ) : error != "" ? (
      <Errorpage error={error} />
    ) : (
      <React.StrictMode>
        {metatags.map((mt) => (
          <meta name="description" content={mt.seo_title} />
        ))}
        <EnquiryPopup
          type={showEnquiry}
          close={() => this.setState({ showEnquiry: -1 })}
          remove={() => {}}
          bike=""
        />
        <HomeTop bg={headerBg} banner={banner} />
        <HomeBrands link={brands} />
        <HomeBikes
          bikes={bikes[0]}
          title="Explore KTM Bikes By"
          enquiry={() => this.setState({ showEnquiry: 0 })}
        />
        <HomeOnroudPrice bikes={bikes[0]} />
        <HomeBikes
          bikes={bikes[1]}
          title="Browse by KTM body types"
          enquiry={() => this.setState({ showEnquiry: 0 })}
        />
        <HomeTestride bikes={bikes[0]} />
        <HomeBikes
          bikes={bikes[2]}
          title="Browse by Engine Displacement"
          enquiry={() => this.setState({ showEnquiry: 0 })}
        />
        <HomeQuestions faq={faq} />
        {/* <HomeTestimonial testimonials={testimonials} /> */}
        <HomeRewies reviews={reviews} />
        <HomeFooter
          bikes={bikes[0]}
          popup={(v) => this.setState({ showEnquiry: v })}
        />
      </React.StrictMode>
    );
  }
}

export default Home;
