function getComaPrice(p) {
  var price = p.toString();
  if (price.length > 3) {
    var rprice = "";
    var j = 0;
    for (let i = price.length - 1; i >= 0; i--) {
      rprice = rprice + price[i];
      if (i < price.length - 1 && i > 0) {
        j++;
        if (j % 2 === 0) rprice = rprice + ",";
      }
    }
    price = "";

    for (let i = rprice.length - 1; i >= 0; i--) {
      price = price + rprice[i];
    }
    return price;
  } else return price;
}

  function makespaceline(s) {
    var ss = "";
    for (let i = 0; i < s.length; i++) {
      if (s[i] === " ") {
        ss = ss + "_";
      } else ss = ss + s[i];
    }
    return ss;
  }

  function makelinespace(s) {
    var ss = "";
    for (let i = 0; i < s.length; i++) {
      if (s[i] === "_") {
        ss = ss + " ";
      } else ss = ss + s[i];
    }
    return ss;
  }

  export { getComaPrice, makespaceline, makelinespace };

// [
//   {
//       "id": 19,
//       "category_id": 9,
//       "title": "Adventure-250",
//       "description": "Adventure-250",
//       "status": 0,
//       "seo_title": "Adventure-250",
//       "seo_tag": "Adventure-250",
//       "seo_desc": "Adventure-250",
//       "file": "uploads/file/1634791526.pdf",
//       "varients": [
//           {
//               "id": 14,
//               "category_id": 9,
//               "bikemodel_id": 19,
//               "title": "Adventure-250(JG48)",
//               "description": "Motorcycle Traction Control, Quickshifter+, Cornering ABS, Offroad ABS & more.",
//               "status": null,
//               "products": [
//                   {
//                       "id": 27,
//                       "category_id": 9,
//                       "bikemodel_id": 19,
//                       "varient_id": 14,
//                       "name": "KTM Adventure 250 (Black)",
//                       "about_bike": "KTM  Adventure 250 (Black), check out the price, specifications, features, colours, mileage, images & more in Bangalore.",
//                       "description": "Test",
//                       "price": 230093,
//                       "status": 0,
//                       "created_at": "2021-10-01T12:01:34.000000Z",
//                       "updated_at": "2021-10-19T07:34:29.000000Z",
//                       "route": "adventure-250",
//                       "images": [
//                           {
//                               "id": 93,
//                               "product_id": 27,
//                               "image": "uploads/images/dd50e4d9c47cdf72d24e89d248edb35b.jpg|uploads/images/2406a0a94c80406914ff2f6c9fdd67d5.jpg|uploads/images/3e7e0224018ab3cf51abb96464d518cd.jpg|uploads/images/a19883fca95d0e5ec7ee6c94c6c32028.jpg"
//                           }
//                       ],
//                       "colors": [
//                           {
//                               "id": 27,
//                               "bikemodel_id": 19,
//                               "varient_id": 14,
//                               "product_id": 27,
//                               "colour_name": "Black",
//                               "colour_code": "#000000"
//                           }
//                       ],
//                       "specs": [
//                           {
//                               "id": 793,
//                               "specname_id": 6,
//                               "specname": "Max Power",
//                               "product_id": 27,
//                               "value": "29.5 bhp @ 9,000 rpm",
//                               "status": 1
//                           },
//                           {
//                               "id": 794,
//                               "specname_id": 7,
//                               "specname": "Max Torque",
//                               "product_id": 27,
//                               "value": "24 Nm @ 7,500 rpm",
//                               "status": 1
//                           },
//                           {
//                               "id": 795,
//                               "specname_id": 8,
//                               "specname": "Cooling System",
//                               "product_id": 27,
//                               "value": "Liquid Cooled",
//                               "status": 1
//                           },
//                           {
//                               "id": 796,
//                               "specname_id": 9,
//                               "specname": "Transmission",
//                               "product_id": 27,
//                               "value": "6 Speed Manual",
//                               "status": 1
//                           },
//                           {
//                               "id": 797,
//                               "specname_id": 10,
//                               "specname": "Transmission Type",
//                               "product_id": 27,
//                               "value": "Chain Drive",
//                               "status": 1
//                           },
//                           {
//                               "id": 799,
//                               "specname_id": 12,
//                               "specname": "Displacement",
//                               "product_id": 27,
//                               "value": "248.8 cc",
//                               "status": 1
//                           },
//                           {
//                               "id": 800,
//                               "specname_id": 13,
//                               "specname": "Cylinders",
//                               "product_id": 27,
//                               "value": "1",
//                               "status": 1
//                           },
//                           {
//                               "id": 801,
//                               "specname_id": 14,
//                               "specname": "Bore",
//                               "product_id": 27,
//                               "value": "72 mm",
//                               "status": 1
//                           },
//                           {
//                               "id": 803,
//                               "specname_id": 16,
//                               "specname": "Valves Per Cylinder",
//                               "product_id": 27,
//                               "value": "4",
//                               "status": 1
//                           },
//                           {
//                               "id": 804,
//                               "specname_id": 17,
//                               "specname": "Compression Ratio",
//                               "product_id": 27,
//                               "value": "12.5 : 1",
//                               "status": 1
//                           },
//                           {
//                               "id": 805,
//                               "specname_id": 18,
//                               "specname": "Ignition",
//                               "product_id": 27,
//                               "value": "Digital",
//                               "status": 1
//                           },
//                           {
//                               "id": 806,
//                               "specname_id": 19,
//                               "specname": "Spark Plugs",
//                               "product_id": 27,
//                               "value": "1 Per Cylinder",
//                               "status": 1
//                           },
//                           {
//                               "id": 808,
//                               "specname_id": 21,
//                               "specname": "Clutch",
//                               "product_id": 27,
//                               "value": "Slipper Clutch (PASC)",
//                               "status": 1
//                           },
//                           {
//                               "id": 809,
//                               "specname_id": 22,
//                               "specname": "Fuel Delivery System",
//                               "product_id": 27,
//                               "value": "Fuel Injection",
//                               "status": 1
//                           },
//                           {
//                               "id": 810,
//                               "specname_id": 23,
//                               "specname": "Fuel Tank Capacity",
//                               "product_id": 27,
//                               "value": "14.5 litres",
//                               "status": 1
//                           },
//                           {
//                               "id": 811,
//                               "specname_id": 24,
//                               "specname": "Reserve Fuel Capacity",
//                               "product_id": 27,
//                               "value": null,
//                               "status": 1
//                           },
//                           {
//                               "id": 813,
//                               "specname_id": 26,
//                               "specname": "Mileage",
//                               "product_id": 27,
//                               "value": null,
//                               "status": 1
//                           },
//                           {
//                               "id": 814,
//                               "specname_id": 27,
//                               "specname": "Top Speed",
//                               "product_id": 27,
//                               "value": null,
//                               "status": 1
//                           },
//                           {
//                               "id": 816,
//                               "specname_id": 29,
//                               "specname": "Front Brake Type",
//                               "product_id": 27,
//                               "value": "Disc",
//                               "status": 1
//                           },
//                           {
//                               "id": 818,
//                               "specname_id": 31,
//                               "specname": "Rear Tyre Size",
//                               "product_id": 27,
//                               "value": "130/80",
//                               "status": 1
//                           },
//                           {
//                               "id": 819,
//                               "specname_id": 32,
//                               "specname": "Tyre Type",
//                               "product_id": 27,
//                               "value": "Tubeless",
//                               "status": 1
//                           },
//                           {
//                               "id": 820,
//                               "specname_id": 33,
//                               "specname": "Radial Tyres",
//                               "product_id": 27,
//                               "value": "Yes",
//                               "status": 1
//                           },
//                           {
//                               "id": 821,
//                               "specname_id": 34,
//                               "specname": "Rear Brake Type",
//                               "product_id": 27,
//                               "value": "Disc",
//                               "status": 1
//                           },
//                           {
//                               "id": 822,
//                               "specname_id": 35,
//                               "specname": "Rear Brake Size",
//                               "product_id": 27,
//                               "value": "230 mm",
//                               "status": 1
//                           },
//                           {
//                               "id": 823,
//                               "specname_id": 36,
//                               "specname": "Calliper Type",
//                               "product_id": 27,
//                               "value": "Front - 4 Piston, Rear- Single Piston Calliper",
//                               "status": 1
//                           },
//                           {
//                               "id": 824,
//                               "specname_id": 37,
//                               "specname": "Wheel Type",
//                               "product_id": 27,
//                               "value": "Alloy",
//                               "status": 1
//                           },
//                           {
//                               "id": 825,
//                               "specname_id": 38,
//                               "specname": "Front Wheel Size",
//                               "product_id": 27,
//                               "value": "19 inch",
//                               "status": 1
//                           },
//                           {
//                               "id": 826,
//                               "specname_id": 39,
//                               "specname": "Rear Wheel Size",
//                               "product_id": 27,
//                               "value": "17 inch",
//                               "status": 1
//                           },
//                           {
//                               "id": 827,
//                               "specname_id": 40,
//                               "specname": "Front Tyre Size",
//                               "product_id": 27,
//                               "value": "100/90",
//                               "status": 1
//                           },
//                           {
//                               "id": 828,
//                               "specname_id": 41,
//                               "specname": "Front Tyre Pressure (Rider)",
//                               "product_id": 27,
//                               "value": "29 psi",
//                               "status": 1
//                           },
//                           {
//                               "id": 829,
//                               "specname_id": 42,
//                               "specname": "Rear Tyre Pressure (Rider)",
//                               "product_id": 27,
//                               "value": "29 psi",
//                               "status": 1
//                           },
//                           {
//                               "id": 830,
//                               "specname_id": 43,
//                               "specname": "Front Tyre Pressure (Rider & Pillion)",
//                               "product_id": 27,
//                               "value": "29 psi",
//                               "status": 1
//                           },
//                           {
//                               "id": 831,
//                               "specname_id": 44,
//                               "specname": "Rear Tyre Pressure (Rider & Pillion)",
//                               "product_id": 27,
//                               "value": "32 psi",
//                               "status": 1
//                           },
//                           {
//                               "id": 833,
//                               "specname_id": 46,
//                               "specname": "Rear Suspension",
//                               "product_id": 27,
//                               "value": "WP APEX - Monoshock",
//                               "status": 1
//                           },
//                           {
//                               "id": 834,
//                               "specname_id": 47,
//                               "specname": "Kerb Weight",
//                               "product_id": 27,
//                               "value": "177 kg",
//                               "status": 1
//                           },
//                           {
//                               "id": 835,
//                               "specname_id": 48,
//                               "specname": "Overall Length",
//                               "product_id": 27,
//                               "value": "2,154 mm",
//                               "status": 1
//                           },
//                           {
//                               "id": 836,
//                               "specname_id": 49,
//                               "specname": "Overall Width",
//                               "product_id": 27,
//                               "value": "900 mm",
//                               "status": 1
//                           },
//                           {
//                               "id": 837,
//                               "specname_id": 50,
//                               "specname": "Overall Height",
//                               "product_id": 27,
//                               "value": "1,263 mm",
//                               "status": 1
//                           },
//                           {
//                               "id": 838,
//                               "specname_id": 51,
//                               "specname": "Wheelbase",
//                               "product_id": 27,
//                               "value": "1,430 mm",
//                               "status": 1
//                           },
//                           {
//                               "id": 839,
//                               "specname_id": 52,
//                               "specname": "Ground Clearance",
//                               "product_id": 27,
//                               "value": "200 mm",
//                               "status": 1
//                           },
//                           {
//                               "id": 840,
//                               "specname_id": 53,
//                               "specname": "Seat Height",
//                               "product_id": 27,
//                               "value": "855 mm",
//                               "status": 1
//                           },
//                           {
//                               "id": 842,
//                               "specname_id": 55,
//                               "specname": "Standard Warranty (Year)",
//                               "product_id": 27,
//                               "value": "2 Year",
//                               "status": 1
//                           },
//                           {
//                               "id": 843,
//                               "specname_id": 56,
//                               "specname": "Standard Warranty (Kilometers)",
//                               "product_id": 27,
//                               "value": null,
//                               "status": 1
//                           },
//                           {
//                               "id": 844,
//                               "specname_id": 57,
//                               "specname": "Odometer",
//                               "product_id": 27,
//                               "value": "Digital",
//                               "status": 1
//                           },
//                           {
//                               "id": 846,
//                               "specname_id": 59,
//                               "specname": "Mobile App Connectivity",
//                               "product_id": 27,
//                               "value": "No",
//                               "status": 1
//                           },
//                           {
//                               "id": 847,
//                               "specname_id": 60,
//                               "specname": "GPS & Navigation",
//                               "product_id": 27,
//                               "value": "No",
//                               "status": 1
//                           },
//                           {
//                               "id": 848,
//                               "specname_id": 61,
//                               "specname": "Pillion Backrest",
//                               "product_id": 27,
//                               "value": "No",
//                               "status": 1
//                           },
//                           {
//                               "id": 849,
//                               "specname_id": 62,
//                               "specname": "Pillion Grabrail",
//                               "product_id": 27,
//                               "value": "Yes",
//                               "status": 1
//                           },
//                           {
//                               "id": 850,
//                               "specname_id": 63,
//                               "specname": "Pillion Seat",
//                               "product_id": 27,
//                               "value": "Yes",
//                               "status": 1
//                           },
//                           {
//                               "id": 851,
//                               "specname_id": 64,
//                               "specname": "Pillion Footrest",
//                               "product_id": 27,
//                               "value": "Yes",
//                               "status": 1
//                           },
//                           {
//                               "id": 854,
//                               "specname_id": 67,
//                               "specname": "Under seat storage",
//                               "product_id": 27,
//                               "value": "No",
//                               "status": 1
//                           },
//                           {
//                               "id": 855,
//                               "specname_id": 68,
//                               "specname": "AHO (Automatic Headlight On)",
//                               "product_id": 27,
//                               "value": "Yes",
//                               "status": 1
//                           },
//                           {
//                               "id": 856,
//                               "specname_id": 69,
//                               "specname": "Speedometer",
//                               "product_id": 27,
//                               "value": "Digital",
//                               "status": 1
//                           },
//                           {
//                               "id": 857,
//                               "specname_id": 70,
//                               "specname": "Fuel Guage",
//                               "product_id": 27,
//                               "value": "Yes",
//                               "status": 1
//                           },
//                           {
//                               "id": 858,
//                               "specname_id": 71,
//                               "specname": "Tachometer",
//                               "product_id": 27,
//                               "value": "Digital",
//                               "status": 1
//                           },
//                           {
//                               "id": 859,
//                               "specname_id": 72,
//                               "specname": "Stand Alarm",
//                               "product_id": 27,
//                               "value": "Yes",
//                               "status": 1
//                           },
//                           {
//                               "id": 860,
//                               "specname_id": 73,
//                               "specname": "Stepped Seat",
//                               "product_id": 27,
//                               "value": "Yes",
//                               "status": 1
//                           },
//                           {
//                               "id": 861,
//                               "specname_id": 74,
//                               "specname": "No. of Tripmeters",
//                               "product_id": 27,
//                               "value": "2",
//                               "status": 1
//                           },
//                           {
//                               "id": 862,
//                               "specname_id": 75,
//                               "specname": "Tripmeter Type",
//                               "product_id": 27,
//                               "value": "Digital",
//                               "status": 1
//                           },
//                           {
//                               "id": 863,
//                               "specname_id": 76,
//                               "specname": "Low Fuel Indicator",
//                               "product_id": 27,
//                               "value": "Yes",
//                               "status": 1
//                           },
//                           {
//                               "id": 864,
//                               "specname_id": 77,
//                               "specname": "Low Oil Indicator",
//                               "product_id": 27,
//                               "value": "Yes",
//                               "status": 1
//                           },
//                           {
//                               "id": 865,
//                               "specname_id": 78,
//                               "specname": "Low Battery Indicator",
//                               "product_id": 27,
//                               "value": "Yes",
//                               "status": 1
//                           },
//                           {
//                               "id": 866,
//                               "specname_id": 79,
//                               "specname": "Digital Fuel Guage",
//                               "product_id": 27,
//                               "value": "Yes",
//                               "status": 1
//                           },
//                           {
//                               "id": 867,
//                               "specname_id": 80,
//                               "specname": "Start Type",
//                               "product_id": 27,
//                               "value": "Electric Start",
//                               "status": 1
//                           },
//                           {
//                               "id": 868,
//                               "specname_id": 81,
//                               "specname": "Shift Light",
//                               "product_id": 27,
//                               "value": "Yes",
//                               "status": 1
//                           },
//                           {
//                               "id": 869,
//                               "specname_id": 82,
//                               "specname": "Killswitch",
//                               "product_id": 27,
//                               "value": "Yes",
//                               "status": 1
//                           },
//                           {
//                               "id": 870,
//                               "specname_id": 83,
//                               "specname": "Clock",
//                               "product_id": 27,
//                               "value": "Yes",
//                               "status": 1
//                           },
//                           {
//                               "id": 872,
//                               "specname_id": 85,
//                               "specname": "Battery",
//                               "product_id": 27,
//                               "value": null,
//                               "status": 1
//                           },
//                           {
//                               "id": 873,
//                               "specname_id": 86,
//                               "specname": "Headlight Type",
//                               "product_id": 27,
//                               "value": "Halogen",
//                               "status": 1
//                           },
//                           {
//                               "id": 874,
//                               "specname_id": 87,
//                               "specname": "Headlight Bulb Type",
//                               "product_id": 27,
//                               "value": "Low Beam: 55 W at 13.5 V, High Beam: 60 W at 13.5 V",
//                               "status": 1
//                           },
//                           {
//                               "id": 875,
//                               "specname_id": 88,
//                               "specname": "Brake/Tail Light",
//                               "product_id": 27,
//                               "value": "LED Tail Lamp",
//                               "status": 1
//                           },
//                           {
//                               "id": 876,
//                               "specname_id": 89,
//                               "specname": "Turn Signal",
//                               "product_id": 27,
//                               "value": "Yes",
//                               "status": 1
//                           },
//                           {
//                               "id": 877,
//                               "specname_id": 90,
//                               "specname": "Pass Light",
//                               "product_id": 27,
//                               "value": "Yes",
//                               "status": 1
//                           },
//                           {
//                               "id": 878,
//                               "specname_id": 91,
//                               "specname": "Fuel Type",
//                               "product_id": 27,
//                               "value": null,
//                               "status": 1
//                           },
//                           {
//                               "id": 882,
//                               "specname_id": 95,
//                               "specname": "Emission Standard",
//                               "product_id": 27,
//                               "value": null,
//                               "status": 1
//                           },
//                           {
//                               "id": 894,
//                               "specname_id": 107,
//                               "specname": "Gear Shifting Pattern",
//                               "product_id": 27,
//                               "value": null,
//                               "status": 1
//                           },
//                           {
//                               "id": 899,
//                               "specname_id": 112,
//                               "specname": "Riding Range",
//                               "product_id": 27,
//                               "value": null,
//                               "status": 1
//                           },
//                           {
//                               "id": 900,
//                               "specname_id": 113,
//                               "specname": "Mileage - ARAI",
//                               "product_id": 27,
//                               "value": null,
//                               "status": 1
//                           },
//                           {
//                               "id": 904,
//                               "specname_id": 117,
//                               "specname": "Front Brake Size",
//                               "product_id": 27,
//                               "value": null,
//                               "status": 1
//                           },
//                           {
//                               "id": 905,
//                               "specname_id": 118,
//                               "specname": "engine capacity",
//                               "product_id": 27,
//                               "value": null,
//                               "status": 1
//                           },
//                           {
//                               "id": 920,
//                               "specname_id": 133,
//                               "specname": "Front Suspension",
//                               "product_id": 27,
//                               "value": null,
//                               "status": 1
//                           },
//                           {
//                               "id": 933,
//                               "specname_id": 146,
//                               "specname": "DRLs (Daytime running lights)",
//                               "product_id": 27,
//                               "value": null,
//                               "status": 1
//                           },
//                           {
//                               "id": 936,
//                               "specname_id": 149,
//                               "specname": "USB charging port",
//                               "product_id": 27,
//                               "value": null,
//                               "status": 1
//                           },
//                           {
//                               "id": 956,
//                               "specname_id": 169,
//                               "specname": "Shift Light",
//                               "product_id": 27,
//                               "value": null,
//                               "status": 1
//                           },
//                           {
//                               "id": 966,
//                               "specname_id": 179,
//                               "specname": "Additional features",
//                               "product_id": 27,
//                               "value": null,
//                               "status": 1
//                           },
//                           {
//                               "id": 974,
//                               "specname_id": 187,
//                               "specname": "Stroke",
//                               "product_id": 27,
//                               "value": null,
//                               "status": 1
//                           },
//                           {
//                               "id": 990,
//                               "specname_id": 203,
//                               "specname": "Braking System",
//                               "product_id": 27,
//                               "value": null,
//                               "status": 1
//                           },
//                           {
//                               "id": 1024,
//                               "specname_id": 237,
//                               "specname": "Front storage box",
//                               "product_id": 27,
//                               "value": null,
//                               "status": 1
//                           },
//                           {
//                               "id": 1046,
//                               "specname_id": 259,
//                               "specname": "Electric System",
//                               "product_id": 27,
//                               "value": null,
//                               "status": 1
//                           }
//                       ]
//                   }
//               ]
//           }
//       ]
//   }
// ]