import React from "react";
import { setUrlimg } from "../module/api_init";
import { getComaPrice } from "../module/simple";
import { ShowMoreButton } from "./button";

class HomeBikes extends React.Component {
  constructor(props) {
    super(props);
    this.state = { page: 0, limit: 1, bikes: props.bikes };
  }

  componentDidMount() {
    // // const [sp, setsp] = useState({ cc: "", milage: "", bhp: "", whight });
    // var b;
    // for (let i = 0; i < this.state.bikes.length; i++) {
    //   for (let j = 0; j < this.state.bikes[i].bikes.length; j++) {
    //     // b = this.state.bikes[i].bikes[j].varients[0].products[0].specs;
    //     b = this.state.bikes[i].bikes[j].specs;
    //     console.log(b);
    //   }
    // }
  }

  render() {
    const { page, limit } = this.state;
    const { bikes } = this.state;
    const setpage = (v) => this.setState({ page: v });
    const setlimit = () => this.setState({ limit: limit + 1 });

    return bikes.length === 0 ? (
      ""
    ) : (
      <div className="center">
        <div className="hm1_bk1_a">
          <div className="cm1_hm_title">{this.props.title}</div>
          <div className="hm1_bk1_b">
            {bikes.map((bike, k) => (
              <div key={k} className="hm1_bk1_bb" onClick={() => setpage(k)}>
                <div
                  className="hm1_bk1_c"
                  style={k === page ? { color: "#FF6600" } : {}}
                >
                  {bike.title}
                </div>
                <div
                  className="hm1_bk1_ca"
                  style={{ visibility: k === page ? "" : "hidden" }}
                />
              </div>
            ))}
          </div>
          <div className="hm1_bk1_ba" />
          <div className="hm1_bk1_d">
            {bikes[page].bikes[0].slice(0, limit * 3).map((bike, k) => (
              <div key={k} className="hm1_bk1_e">
                <div
                  onClick={() =>
                    window.location.assign(`/ktm-bikes/${bike.route}`)
                  }
                >
                  <div className="hm1_bk1_ez">
                    <img
                      alt="No Image"
                      width="291"
                      height="194"
                      src={
                        bike.image.split("|").length === 0
                          ? ""
                          : setUrlimg(bike.image.split("|")[0])
                      }
                    />
                  </div>
                  <div className="hm1_bk1_ea">{bike.name}</div>
                  {/* <div className="hm1_bk1_eb">
                    349 cc, 35 kmpl, 20.2 bhp, 191 kg
                  </div> */}
                  <div className="hm1_bk1_ec">Ex-Showroom Price, Bengaluru</div>
                  <div className="hm1_bk1_ed">
                    ₹ {getComaPrice(bike.price)} onwards
                  </div>
                </div>

                <div
                  onClick={() => this.props.enquiry()}
                  className="hm1_bk1_ee"
                >
                  Check On-road Price
                </div>
              </div>
            ))}
          </div>
          {bikes[page].bikes[0].length > limit * 3 ? (
            <ShowMoreButton onclick={setlimit} />
          ) : null}
          {/* <div className="hm1_bk1_f"></div> */}
        </div>
      </div>
    );
  }
}

export default HomeBikes;
