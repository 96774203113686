import { dev_spec } from "./dev";

function BikeSpecification({ bike, spec }) {
  return (
    <div id="specification" className="center">
      <div className="bk1_sp1_a">
        <div className="bk1_sp1_aa">{bike.name} </div>
        {/* {items.map((item) => ( */}
        <div className="bk1_sp1_ab">
          <div className="bk1_sp1_aba">Specification </div>
          <div className="bk1_sp1_abb">
            {spec.map((data) =>
              data.value !== null ? (
                <div className="bk1_sp1_abba">
                  <div className="bk1_sp1_abbaa">{data.specname}</div>
                  <div className="bk1_sp1_abbab">{data.value}</div>
                </div>
              ) : null
            )}
            <div className="bk1_sp1_abbb" />
          </div>
        </div>
        {/* ))} */}
      </div>
    </div>
  );
}

export default BikeSpecification;
