import React from "react";
import BikeBodyHeader from "../compnent/bike_body_header";
import BikeColor from "../compnent/bike_color";
import BikeDetails from "../compnent/bike_details";
import BikePrice from "../compnent/bike_price";
import BikeSpecification from "../compnent/bike_spes";
import BikeSummery from "../compnent/bike_summery";
import Header from "../compnent/header";
import HomeBikes from "../compnent/home_bikes";
import HomeFooter from "../compnent/home_footer";
import { Errorpage, LoadingPage } from "../compnent/loading_error";
import { EnquiryPopup } from "../compnent/popups";
import { executeScroll, getAll } from "../method/bike";
import MetaTags from "react-meta-tags";
import { Helmet } from "react-helmet";

class Bike extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      bike: [],
      bikes: [],
      loading: true,
      error: "",
      varient: 0,
      color: 0,
      imgcount: 0,
      showEnquiry: -1,
      emiclicked: false,
    };
  }

  componentDidMount() {
    getAll((v) => this.setState(v));
  }

  render() {
    const setpage = (v) => this.setState({ page: v });
    const setimgcount = (v) => this.setState({ imgcount: v });
    const setemiclicked = () => this.setState({ emiclicked: true });

    const {
      page,
      bike,
      bikes,
      loading,
      error,
      varient,
      color,
      imgcount,
      showEnquiry,
      emiclicked,
    } = this.state;

    return loading ? (
      <LoadingPage />
    ) : error != "" ? (
      <Errorpage error={error} />
    ) : (
      <React.StrictMode>
        <Helmet>
          <title>Kalyani KTM-{bike[0].seo_title}</title>
        </Helmet>
        <MetaTags>
          <meta charset="UTF-8" />
          <meta name="description" content={bike[0].seo_desc} />
          <meta name="keywords" content={bike[0].seo_tag} />
          <meta name="title" content={bike[0].seo_title} />
        </MetaTags>

        <EnquiryPopup
          type={showEnquiry}
          close={() => this.setState({ showEnquiry: -1 })}
          remove={() => (showEnquiry == 2 ? setemiclicked() : null)}
          bike={bike[0].title}
        />
        <Header />
        <BikeBodyHeader
          executeScroll={(id, k) => executeScroll(id, k, setpage)}
          page={page}
        />
        <BikeDetails
          setenquiry={(v) => this.setState({ showEnquiry: v })}
          setvarient={(v) => this.setState({ varient: v })}
          varients={bike[0]}
          varient={varient}
          color={color}
          setimgcount={setimgcount}
          imgcount={imgcount}
          emiclicked={emiclicked}
        />
        <BikeColor
          colors={bike[0].varients[varient]}
          setcolor={(v) => this.setState({ color: v })}
          cColor={color}
          setimgcount={setimgcount}
        />
        <BikeSpecification
          bike={bike[0].varients[varient].products[color]}
          spec={bike[0].varients[varient].specifications}
        />
        <BikePrice
          bike={bike[0]}
          setenquiry={(v) => this.setState({ showEnquiry: v })}
        />
        <BikeSummery bike={bike[0].varients[varient].products[color]} />
        <HomeBikes
          bikes={bikes[0]}
          enquiry={() => this.setState({ showEnquiry: 0 })}
        />
        <HomeFooter
          bikes={bikes[0]}
          popup={(v) => this.setState({ showEnquiry: v })}
        />
      </React.StrictMode>
    );
  }
}

export default Bike;
