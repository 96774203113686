import React, { useEffect, useState } from "react";
import Header from "../compnent/header";
import HomeFooter from "../compnent/home_footer";
import { Errorpage, LoadingPage } from "../compnent/loading_error";
import { EnquiryPopup } from "../compnent/popups";
import SingleBike from "../compnent/single_bike";
import { getAllbike } from "../method/allbikes";

function AllBike() {
  const [selectitemname, setselectitemname] = useState("");
  const [items, setitems] = useState([]);
  const [loading, setloading] = useState(true);
  const [error, seterror] = useState("");
  const [showEnquiry, setshowEnquiry] = useState(-1);

  useEffect(() => getAllbike(setitems, setloading, seterror), []);

  return loading ? (
    <LoadingPage />
  ) : error != "" ? (
    <Errorpage error={error} />
  ) : (
    <React.StrictMode>
      <EnquiryPopup
        type={showEnquiry}
        close={() => setshowEnquiry(-1)}
        remove={() => {}}
        bike={selectitemname}
      />
      <Header />
      <div className="center">
        <div className="ab1_bk1_a">
          <div className="ab1_bk1_aa">
            {items.length === 0
              ? "Loading.."
              : items.map((item, k) => (
                  <SingleBike
                    key={k}
                    bike={item}
                    show={() => {
                      setshowEnquiry(0);
                      setselectitemname(item.name);
                    }}
                  />
                ))}
          </div>
        </div>
      </div>
      <HomeFooter bikes={null} popup={(v) => setshowEnquiry(v)} />
    </React.StrictMode>
  );
}

export default AllBike;
